import type {FilterStates} from '@Libraries/email-marketing-campaigns-audience-library';
import {getMailingListIdFromUrlHash} from '@Libraries/email-marketing-campaigns-audience-library';
import useMailingListAndSignupForms from '@Hooks/email-marketing-campaign/useMailingListAndSignupForms';
import type {
  AddMailingListPanelContainerQuery,
  AudienceMapCardData,
  AudienceMapCardDataMap,
  AddMailingListPanelContainerQueryParams,
  MailingList,
} from '@Components/mailing-list-wizard/mailing-list-wizard.types';
import type {UserCustomFieldMetaMap} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience.types';
import {useContext, useRef} from 'react';
import {ModalDetails} from '@Components/modal/modal-context';
import {useContainerQuery} from '@Hooks/useContainerQuery';
import {containerQuery} from '@Components/mailing-list-wizard/mailing-list-wizard-container-queries-context';
import {useAppSelector} from '@/hooks';

export const useDoMailingListsExists = (): boolean => {
  const mailingListId = getMailingListIdFromUrlHash();
  const {isMailingListSignUpFormLoading: isMailingListCountLoading, mailingListCount} = useMailingListAndSignupForms();
  return !mailingListId && !isMailingListCountLoading && mailingListCount > 0;
};

export const useUserCustomFieldsMetaMap = (): UserCustomFieldMetaMap | null => {
  return useAppSelector((state): UserCustomFieldMetaMap | null => {
    return state.emailMarketingCampaignAudience.userCustomFieldsMetaMap;
  });
};

export const useAddMailingListNextButtonToolTipText = (): string | undefined => {
  return useAppSelector((state): string | undefined => {
    return state.emailMarketingCampaignAudience.addMailingListPanel.panelNextButtonTooltipText;
  });
};

export const useAudienceMapCardDataMap = (): AudienceMapCardDataMap | undefined => {
  return useAppSelector((state): AudienceMapCardDataMap | undefined => {
    return state.emailMarketingCampaignAudience.addMailingListPanel.audienceMapCardDataMap;
  });
};

export const useAudienceMapCardDataForCard = (cardId: string): AudienceMapCardData | undefined => {
  return useAppSelector((state): AudienceMapCardData | undefined => {
    const {audienceMapCardDataMap} = state.emailMarketingCampaignAudience.addMailingListPanel;
    if (!audienceMapCardDataMap || !Object.keys(audienceMapCardDataMap).includes(cardId)) {
      return undefined;
    }

    return audienceMapCardDataMap[cardId];
  });
};

export const useAddMailingListPanelErrorMessage = (): string | undefined => {
  return useAppSelector((state): string | undefined => {
    return state.emailMarketingCampaignAudience.addMailingListPanel.panelErrorMessage;
  });
};

export const useAddMailingListPanelInfoMessage = (): string | undefined => {
  return useAppSelector((state): string | undefined => {
    return state.emailMarketingCampaignAudience.addMailingListPanel.panelInfoMessage;
  });
};

export const useMailingListDataById = (idMailingList: number): MailingList => {
  return useAppSelector((state): MailingList => {
    return state.emailMarketingCampaignAudience.mailingLists[idMailingList];
  });
};

export const useFiltersForMailingList = (idMailingList: number): FilterStates => {
  return useAppSelector((state): FilterStates => {
    return state.emailMarketingCampaignAudience.mailingLists[idMailingList].filters;
  });
};

export const useAddMailingListPanelContainerQuery = (): AddMailingListPanelContainerQueryParams => {
  const containerRef = useRef<HTMLElement>(null);
  const params = useContainerQuery(containerRef, containerQuery);
  const modalDetails = useContext(ModalDetails);
  const isMobileModal = modalDetails?.isMobileModal;

  const containerQueryParamProps: AddMailingListPanelContainerQuery = {
    isSmallScreen: isMobileModal ? params.small : false,
    isLargeScreen: params.large,
  };

  return {
    containerQueryParamProps,
    containerRef,
    hasContainerQueryInitialized: params.small !== undefined,
  };
};
