import type {LoadingStates} from '@Utils/loading.util';
import type {SocialMediaProfile} from '@Components/social-media-profile-links/social-media-profile-links.types';
import type {BusinessProfilesActivityLogs} from '@Panels/business-profiles-activity-panel/business-profiles-activity-panel.types';

export interface PreviewFontStyleInfo {
  fontFamily?: string;
  fontColor?: string;
}

export interface BusinessCardPreviewStyleInfo {
  color?: string;
  logoUrl?: string;
  font?: PreviewFontStyleInfo;
}

export interface BusinessProfilesWizardReducerState extends BusinessCardPreviewStyleInfo {
  businessProfile?: BusinessProfile;
  currentStep: BusinessProfileWizardStep;
  isSavingChanges: boolean;
  loadingState: LoadingStates;
  mode: BusinessProfilesWizardMode;
  googleMapAPIKey: string;
  allBusinessProfiles?: BusinessProfile[];
  isFieldsErrorState?: boolean;
  isImportingProfile?: boolean;
  isImportedProfile?: boolean;
  activityLogs: BusinessProfilesActivityLogs[];
  allCategories: Record<string, BusinessProfileCategory>;
  savedBusinessProfile?: BusinessProfile;
}

export interface BusinessProfile {
  idBusinessProfile: number;
  hashedId: string;
  name: string;
  types: Partial<Record<BusinessProfileType, string>>;
  categories: Record<string, string>;
  displayableBusinessHours?: string;
  website?: string;
  contactEmail?: string;
  phoneNumber?: string;
  dialCode?: string;
  state?: BusinessProfileState;
  socialMediaProfileLinks: Record<SocialMediaProfile, string>;
  address?: BusinessAddress;
  idBrand?: string;
  googleLocationId?: string;
  businessHours?: BusinessHoursMap;
  autoFilledEmail?: string;
}

export type BusinessProfileResponse = {
  profileExists: boolean;
  businessProfile: BusinessProfile;
  googleMapAPIKey: string;
  emailId: string;
  allCategories: Record<string, BusinessProfileCategory>;
};

export type BusinessProfileCategory = {
  gcid: string;
  displayName: string;
};

export enum BusinessProfileState {
  GOOGLE_IMPORTED_SAVED = 'google_imported_saved',
  GOOGLE_IMPORTED_UNSAVED = 'google_imported_unsaved',
  USER_CREATED_UNSAVED = 'user_created_unsaved',
  USER_CREATED_SAVED = 'user_created_saved',
}

export enum BusinessProfilesWizardMode {
  NONE = 0,
  EDITING = 1,
  CREATING = 2,
  SAVED = 3,
}

export enum BusinessProfileWizardStep {
  BASIC_INFO = 1,
  CONTACT_DETAILS = 2,
  BRAND_KIT = 3,
  LAST = 4,
}

export enum BusinessProfileType {
  NONE = 'none',
  ONLINE_RETAIL = 'online_retail',
  LOCAL_STORE = 'local_store',
  SERVICE_BUSINESS = 'service_business',
}

export interface BusinessProfilesWizardContainerQuery {
  isMobileScreen?: boolean;
  isTabletScreen?: boolean;
  isLaptopScreen?: boolean;
  isRegularDesktopScreen?: boolean;
  isLargeDesktopScreen?: boolean;
}

export enum BusinessHoursDay {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export interface BusinessHours {
  from: BusinessHoursTimeParts;
  to: BusinessHoursTimeParts;
}

export interface BusinessHoursTimeParts {
  hour: number;
  minutes: number;
}

export type BusinessHoursMap = {
  [key in BusinessHoursDay]?: BusinessHours;
};

export interface BusinessAddress {
  country?: string;
  address?: string[];
  sector?: string;
  city?: string;
  postalCode?: string;
  longitude?: string;
  latitude?: string;
  locationName?: string;
  googleMapsPlaceId?: string;
  idAddress?: number;
}

export interface BusinessProfileAjaxResponse {
  idBusinessProfile: number;
  hashedIdBusinessProfile: string;
  idAddress: number | null;
}

export interface BusinessProfileData {
  businessProfile?: BusinessProfile;
  businessProfileState: BusinessProfileState;
  businessHours?: BusinessHoursMap;
  isImported?: boolean;
}

export interface BusinessProfileDeleteData {
  idBusinessProfile?: number;
}
