import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {EmailCampaign} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'email-campaign-dialog-panel';

export interface EmailCampaignDialogModalProps {
  campaign: EmailCampaign;
}

export default function EmailCampaignDialogModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.EMAIL_CAMPAIGN_DIALOG_MODAL];
  });

  const height = '588px';
  const width = '867px';

  return (
    <Modal modalId={MODALS_IDS.EMAIL_CAMPAIGN_DIALOG_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width} className="_overflow-visible">
      <LazyPanel
        panelDirectoryName="email-campaign-dialog-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openEmailCampaignDialogModal = (props: EmailCampaignDialogModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.EMAIL_CAMPAIGN_DIALOG_MODAL,
      panelProps: props,
    })
  );
};
