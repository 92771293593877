import type {GridAudienceItemStorage} from '@Components/email-marketing-campaign-audience/components/audience-grid';
import type {GridEmailCampaignAudienceItemData} from '@Components/base-grid/components/grid-email-campaign-audience-item';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import type {GridStorageTypes} from '@Components/base-grid';
import type {MailingListsSignUpFormCountResponse} from '@Hooks/email-marketing-campaign/useMailingListAndSignupForms';
import {PILL_THEME} from '@Components/pill';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import {AddMailingListStep} from '@Panels/add-mailing-list-panel/add-mailing-list-panel.types';
import {openAddMailingListModal, PANEL_ID} from '@Modals/add-mailing-list-modal';
import type {CreateMailingListResponse, MailingList, RawCustomFieldValue} from '@Components/mailing-list-wizard/mailing-list-wizard.types';
import type {ResponseAudience} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-thunk';
import type {AjaxResponse} from '@Utils/ajax.util';
import {getSeparatedStringFromCamelCase, LetterCase} from '@Utils/string.util';
import type {
  Audience,
  AudienceCustomFieldData,
  RawAudienceFieldNames,
  UserCustomFieldMeta,
  UserCustomFieldMetaMap,
} from '@/components/email-marketing-campaign-audience/email-marketing-campaign-audience.types';
import {AudienceEmailState} from '@/components/email-marketing-campaign-audience/email-marketing-campaign-audience.types';

export const GRID_ID = 'audienceGrid';

export const LAZY_LOAD_EMAILS_BATCH_SIZE = 100;

export const SPAM_SCORE_THRESHOLD = 0.2;

export const DEFAULT_MAILING_LIST_ID = -1;

export const audienceContainerQuery: ContainerQueryObject = {
  small: {
    minWidth: 0,
    maxWidth: 700,
  },
  medium: {
    minWidth: 700,
    maxWidth: 1000,
  },
  large: {
    minWidth: 1000,
  },
};

export enum ErrorCode {
  SPAM_LIST = 'spam-list',
}

export type FilterStates = {
  [key in AudienceEmailState]?: boolean;
};

export const findGridItem = (grid: GridStorageTypes, gridItemId: number): GridAudienceItemStorage => {
  return grid.items.find((item: GridItemStorage) => {
    return parseInt(item.id, 10) === gridItemId;
  }) as GridAudienceItemStorage;
};

export const getEmailStatesEnumValues = (): Array<AudienceEmailState> => {
  return Object.values(AudienceEmailState).filter((v) => {
    return !isNaN(Number(v));
  }) as Array<AudienceEmailState>;
};

export const getAllEnabledFilters = (): FilterStates => {
  return getEmailStatesEnumValues().reduce((obj, state) => {
    return {...obj, [state]: true};
  }, {});
};

export const getAllDisabledFilters = (): FilterStates => {
  return getEmailStatesEnumValues().reduce((obj, state) => {
    return {...obj, [state]: false};
  }, {});
};

export const areAllFiltersChecked = (filters: FilterStates): boolean => {
  return Object.values(filters).every((item) => {
    return item;
  });
};

export const getSelectedFiltersCount = (filters: FilterStates): number => {
  return Object.values(filters).reduce((previousValue, currentValue) => {
    return previousValue + (currentValue ? 1 : 0);
  }, 0);
};

export const getDisplayableEmailState = (emailState: AudienceEmailState): string => {
  switch (emailState) {
    case AudienceEmailState.EMAIL_STATE_VALID:
      return window.i18next.t('pmwjs_valid');
    case AudienceEmailState.EMAIL_STATE_INVALID:
      return window.i18next.t('pmwjs_invalid');
    case AudienceEmailState.EMAIL_STATE_UNSUBSCRIBED:
      return window.i18next.t('pmwjs_unsubscribed');
    case AudienceEmailState.EMAIL_STATE_COMPLAINT:
      return window.i18next.t('pmwjs_complaint');
    case AudienceEmailState.EMAIL_STATE_BOUNCE:
      return window.i18next.t('pmwjs_bounce');
    default:
      return '';
  }
};

export const getPillThemeByEmailState = (emailState: AudienceEmailState): PILL_THEME => {
  switch (emailState) {
    case AudienceEmailState.EMAIL_STATE_VALID:
      return PILL_THEME.SUCCESS;
    case AudienceEmailState.EMAIL_STATE_INVALID:
    case AudienceEmailState.EMAIL_STATE_UNSUBSCRIBED:
    case AudienceEmailState.EMAIL_STATE_COMPLAINT:
    case AudienceEmailState.EMAIL_STATE_BOUNCE:
      return PILL_THEME.DANGER;
    default:
      return PILL_THEME.SUCCESS;
  }
};

export const getDisplayableEmailStateTooltip = (emailState: AudienceEmailState): string => {
  switch (emailState) {
    case AudienceEmailState.EMAIL_STATE_VALID:
      return window.i18next.t('pmwjs_valid_tooltip');
    case AudienceEmailState.EMAIL_STATE_INVALID:
      return window.i18next.t('pmwjs_invalid_tooltip');
    case AudienceEmailState.EMAIL_STATE_UNSUBSCRIBED:
      return window.i18next.t('pmwjs_unsubscribed_tooltip');
    case AudienceEmailState.EMAIL_STATE_COMPLAINT:
      return window.i18next.t('pmwjs_complaint_tooltip');
    case AudienceEmailState.EMAIL_STATE_BOUNCE:
      return window.i18next.t('pmwjs_bounce_tooltip');
    default:
      return '';
  }
};

export const onAddAudience = (successResponse: CreateMailingListResponse, addItemsCallback: (audienceData: GridEmailCampaignAudienceItemData) => void): void => {
  const {audienceViewData} = successResponse;

  if (audienceViewData) {
    addItemsCallback(audienceViewData);
    window.PMW.closeModalDialog('add-mailing-list-emails');
  }
};

export const onClickCreateMailingListButton = (onAddAudienceSuccess: (mailingListResponse: CreateMailingListResponse) => void): void => {
  openAddMailingListModal({
    panelId: PANEL_ID,
    initialStep: AddMailingListStep.CHOOSE_UPLOAD_METHOD,
    onAddAudienceSuccess,
    doLoadAudiences: true,
  });
};

export const getAudienceUrl = (uri = ''): string => {
  return window.PMW.util.site_url(`posters/mine#/email-marketing-campaign-audience/${uri}`);
};

export const castIdToStringForBaseGrid = (id: number): string => {
  return id.toString();
};

export const getMailingListIdFromUrlHash = (): number | undefined => {
  return parseInt(window.location.hash.split('/')[2], 10);
};

const getCSVHeader = (userCustomFieldMetaMap: UserCustomFieldMetaMap): string[] => {
  const defaultFields: Array<RawAudienceFieldNames> = ['email', 'firstName', 'lastName', 'phoneNumber', 'address', 'birthday'];

  const sortedCustomFieldMeta: UserCustomFieldMeta[] = Object.values(userCustomFieldMetaMap).sort((a: UserCustomFieldMeta, b: UserCustomFieldMeta) => {
    return a.order - b.order;
  });
  const customFieldNames = sortedCustomFieldMeta.map((customFieldMeta: UserCustomFieldMeta): string => {
    return customFieldMeta.label;
  });

  return [...(defaultFields as string[]), ...customFieldNames].map((fieldName) => {
    return getSeparatedStringFromCamelCase(fieldName, LetterCase.SENTENCE_CASE);
  });
};

const getCSVDataFromAudiences = (audienceData: Array<Audience>, userCustomFieldMetaMap: UserCustomFieldMetaMap): string[] => {
  const sortedCustomFieldIds: number[] = Object.values(userCustomFieldMetaMap)
    .sort((a: UserCustomFieldMeta, b: UserCustomFieldMeta) => {
      return a.order - b.order;
    })
    .map((customFieldMeta) => {
      return customFieldMeta.idUserHasCustomField;
    });

  return audienceData.map((audienceItem: Audience): string => {
    const audienceItemArray = [];

    audienceItemArray.push(`"${audienceItem.email}"`);
    audienceItemArray.push(`"${audienceItem.firstName ?? ''}"`);
    audienceItemArray.push(`"${audienceItem.lastName ?? ''}"`);
    audienceItemArray.push(`"${audienceItem.phoneNumber ?? ''}"`);
    audienceItemArray.push(`"${audienceItem.address ?? ''}"`);
    audienceItemArray.push(`"${String(audienceItem.birthday)}"`);

    const audienceCustomFields = audienceItem.customFields;
    if (audienceCustomFields) {
      audienceItemArray.push(
        sortedCustomFieldIds
          .map((idUserHasCustomField: number): string => {
            return `"${audienceCustomFields[idUserHasCustomField]}"`;
          })
          .join(',')
      );
    }

    return audienceItemArray.join(',');
  });
};

export const downloadCSV = (audienceData: Array<Audience>, userCustomFieldMetaMap: UserCustomFieldMetaMap, fileName = 'download'): void => {
  const csvHeader = getCSVHeader(userCustomFieldMetaMap);
  const csvData = getCSVDataFromAudiences(audienceData, userCustomFieldMetaMap);

  const csvDataToExport = [csvHeader.join(','), csvData.join('\n')].join('\n');

  const csvBlob = new Blob([csvDataToExport], {
    type: `data:text/csv;charset=utf-8`,
  });

  const encodedUri = URL.createObjectURL(csvBlob);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const removeContentLoadingState = (): void => {
  // eslint-disable-next-line no-restricted-globals
  $('#content').removeClass('-loading');
};

export const showContentLoadingState = (): void => {
  // eslint-disable-next-line no-restricted-globals
  $('#content').addClass('-loading');
};

export const areAllEmailsLoadedForMailingList = (mailingListData: MailingList): boolean => {
  return mailingListData && mailingListData.totalEmailsCount === mailingListData.emailIds.length;
};

export const getErrorMessage = (code: string): string => {
  switch (code) {
    case ErrorCode.SPAM_LIST.valueOf():
      return window.i18next.t('pmwjs_mailing_list_spam_audience', {percentage: `${SPAM_SCORE_THRESHOLD * 100}%`});
    default:
      return window.i18next.t('pmwjs_error_message');
  }
};

export const attachScrollListener = (onScrolledToBottom: VoidFunction): void => {
  // eslint-disable-next-line no-restricted-globals
  const contentJquery = $(window);
  // eslint-disable-next-line no-restricted-globals
  const documentJquery = $(document);

  contentJquery.on(
    'scroll',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.PMW.debounce(() => {
      const contentHeight = contentJquery.height();
      const documentHeight = documentJquery.height();
      const contentScrollTop = contentJquery.scrollTop();

      if (contentScrollTop === undefined || contentHeight === undefined || documentHeight === undefined) {
        return;
      }

      if (contentScrollTop + contentHeight > documentHeight - 200) {
        onScrolledToBottom();
      }
    }, 100)
  );
};

export const getMailingListsSignUpFormCount = async (): Promise<MailingListsSignUpFormCountResponse> => {
  return (await window.PMW.readLocal('emailmarketing/getMailingListsSignUpFormsCountForUser')) as MailingListsSignUpFormCountResponse;
};

export const prepareCustomFieldDataFromRawCustomFields = (customFieldArray: RawCustomFieldValue[] | undefined): AudienceCustomFieldData => {
  if (!customFieldArray) {
    return {};
  }

  let audienceCustomFieldData: AudienceCustomFieldData = {};
  customFieldArray.forEach((customField: RawCustomFieldValue) => {
    audienceCustomFieldData = {...audienceCustomFieldData, [customField.idUserHasCustomField]: customField.value};
  });

  return audienceCustomFieldData;
};

export const populateAudienceCustomFieldsFromAudienceResponse = (audienceResponse: ResponseAudience): Audience => {
  if (!audienceResponse || !audienceResponse.customFields) {
    return audienceResponse as Audience;
  }

  const customFields = prepareCustomFieldDataFromRawCustomFields(audienceResponse.customFields);
  return {...audienceResponse, customFields};
};

export const requestUpdateCustomFieldValue = async (idAudience: number, idCustomField: number, value: string): Promise<AjaxResponse> => {
  return (await window.PMW.writeLocal('emailmarketing/upsertCustomFieldValueForAudience', {
    idAudience,
    idCustomField,
    value,
  })) as AjaxResponse;
};
